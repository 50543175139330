<template>
    <div  class="foot">
        <div class="foot_first">
            <div class="foot_first_text"> 
                <div class="foot_first_text_title">联系我们</div>
                <div class="foot_first_text_info">电话：400-9686716</div>
                <div class="foot_first_text_info">邮编：310053</div>
                <div class="foot_first_text_info">杭州市滨江区江南大道1088号中南国际25楼2501</div>
            </div>
            <div class="foot_first_right"></div>
        </div>
        <div class="foot_foot"><a style="color:#748ea6;text-decoration: underline;text-decoration-color:#748ea6;" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2023006304号-1</a></div>
        <div class="foot_foot">杭州吉美特科技有限公司版权所有 增值业务电信许可证号：浙B2-20230608 文化许可证编号：浙网文〔2023〕4049-163号</div>
    </div>
  </template>
  
  
  <script>
  
  
  
  
  export default {
    name: 'Home',
    components: {
 
    },
    data(){
          return{
            run:true,
         
          }
    },
    methods:{
 
      
    },
      mounted() {



      },
  
  
  }
  </script>

  <style lang="stylus">
    .foot
        width 100%
        padding 30px 174px 
        background #000
        .foot_first_text_title
            font-size 20px
            color #fff
        .foot_first_text_info
            padding-top 10px
            color #748EA6
        .foot_first
            display flex
            justify-content space-between
            padding-bottom 48px
        .foot_foot
            text-align center
            color #748EA6
            font-size 18px
            padding-top 20px
        .foot_first_text
            text-align left
  </style>